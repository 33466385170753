import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';

import React, { useEffect } from 'react';

import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSuperHubStore } from '..';

/** Retain a consistent empty array object to prevent inequality checks. */
const emptyArray = [];

interface ConnectSuperHubDocumentToApiProps {
  children: React.ReactNode;
  /**
   * Whether SWR should revalidate page data on focus. Default is `false`.
   */
  revalidateOnFocus?: boolean;
  /**
   * Page document slug to continually hydrate and keep in sync via SWR and our
   * API endpoint.
   */
  slug?: string;
}

/**
 * Connects our SuperHub store's document slice to the page API endpoint and
 * hydrates its data based on the provided slug. Fetches new data whenever
 * rendered and updates the store.
 */
export function ConnectSuperHubDocumentToApi({
  children,
  revalidateOnFocus = false,
  slug,
}: ConnectSuperHubDocumentToApiProps) {
  const [getApiEndpoint, initialize, routeSection] = useSuperHubStore(s => [
    s.getApiEndpoint,
    s.document.initialize,
    s.routeSection,
  ]);
  const apiUrl = routeSection && slug ? getApiEndpoint(slug) : null;

  const {
    data: documentData,
    isLoading: isDocumentLoading,
    swrKey,
  } = useReadmeApi<ReadGuideType>(apiUrl, {
    swr: {
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
  });

  // Temporarily limit custom block data fetching to only the docs and reference sections.
  // We'll want to load custom blocks for custom pages and changelogs once there's API support.
  const isCustomBlockRoute = ['docs', 'reference'].includes(routeSection || '');

  const { data: customBlocksData, isLoading: isCustomBlocksLoading } = useReadmeApi<ReadCustomBlockGitCollectionType>(
    apiUrl && isCustomBlockRoute ? `${apiUrl}/custom_blocks` : null,
    {
      swr: {
        revalidateOnFocus,
        shouldRetryOnError: true,
      },
    },
  );

  useEffect(() => {
    initialize({
      customBlocks: isCustomBlockRoute ? customBlocksData?.data : emptyArray,
      data: documentData?.data || null,
      isLoading: isDocumentLoading || isCustomBlocksLoading,
      swrKey,
    });
  }, [
    customBlocksData?.data,
    documentData?.data,
    initialize,
    isCustomBlockRoute,
    isCustomBlocksLoading,
    isDocumentLoading,
    routeSection,
    swrKey,
  ]);

  return <>{children}</>;
}
