import type { SuperHubDocumentSlice } from './Document';
import type { SuperHubEditorSlice } from './Editor';
import type { SuperHubSidebarSlice } from './Sidebar';
import type { Flag, ProjectClientSide } from '@readme/backend/models/project/types';

import { matchPath } from 'react-router-dom';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { projectStore } from '@core/store/Project';

import { SuperHubHashRoutePaths, SuperHubRoutePaths } from '@routes/SuperHub/types';
import type { SuperHubHashRouteParams, SuperHubRouteParams } from '@routes/SuperHub/types';

import { actionLog, createBoundedUseStore, isClient } from '../util';

import { createSuperHubDocumentSlice } from './Document';
import { createSuperHubEditorSlice } from './Editor';
import { createSuperHubSidebarSlice } from './Sidebar';

interface SuperHubStoreState {
  /**
   * Base URL pointing to our v2 API endpoint. Consumers can simply start with
   * this and tack on additional segments to reach different API endpoints.
   * e.g. `fetch(`${apiBaseUrl}/sidebar`)`
   * @example /subdomain/api-next/v2/versions/1.0.0
   */
  apiBaseUrl: string;

  /**
   * True when pages goes into "edit" mode.
   */
  isEditing: boolean;

  /**
   * Indicates that the store has been initialized to its beginning state via
   * `initialize()` action and lets connected subscribers know that it is ready
   * for consumption.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Indicates whether the current route is within our Reference section. We
   * need this b/c Reference contains other routes like API Definitions,
   * Realtime and endpoint pages that have different routes associated to them.
   */
  isReferenceRoute: boolean;

  /**
   * Indicates whether SuperHub is enabled for the current project. Will be
   * `true` for all user roles including both admins and logged-out users.
   */
  isSuperHub: boolean;

  /**
   * Indicates whether the current user is a SuperHub admin that can manage
   * project settings and edit content.
   */
  isSuperHubAdmin: boolean;

  /**
   * Indicates whether SuperHub should allow access to admin features that are
   * still under development.
   */
  isSuperHubDevelopment: boolean;

  /**
   * Determines which layout to render the SuperHub in.
   * - `default`: Default layout for the SuperHub.
   * - `aside`: Used when comparing two documents side-by-side.
   * - `aside-standalone`: Same as `aside` but without a top nav bar.
   * - `edit`: Used when editing a document.
   * - `settings`: Used when configuring settings. The Hub content is hidden in this layout.
   * - `settings-preview`: Used when configuring settings side-by-side with the Hub content.
   */
  layout: 'aside-standalone' | 'aside' | 'default' | 'edit' | 'settings-preview' | 'settings';

  /**
   * Refers to the top-level route section that is being viewed on the Hub such
   * as `docs`, `reference`, `changelog`, etc. For example. when navigating to
   * `/reference/pet`, the top-level route section is `reference`. This is
   * typically but not always the first segment of the route URL.
   */
  routeSection: NonNullable<SuperHubRouteParams['section']> | null;

  /**
   * Contains the page slug from the hub route that is currently being viewed.
   * For example, when viewing `/docs/getting-started`, the slug is
   * `getting-started`, typically the second segment of the hub route.
   */
  slug: string | null;

  /**
   * Project subdomain that is currently in view, e.g. `my-project-slug`
   */
  subdomain: string | null;

  /**
   * Clean project version that is currently in view, e.g. `1.0.0`.
   */
  version: string | null;
}

interface SuperHubStoreAction {
  /**
   * Generates the URL path to reach the API endpoint related to the current
   * document type. Used by actions to create, edit or view documents.
   * @example
   * /subdomain/api-next/v2/versions/1.0.0/guides
   * /subdomain/api-next/v2/versions/1.0.0/custom_pages/slug
   */
  getApiEndpoint: (slug?: string) => string;

  /**
   * Initializes the store based on the provided settings. In order to enable
   * SSR, this needs to be called inline and high up in the rendering tree to
   * make the store's state ready before rendering components downstream during
   * both SSR and the initial CSR.
   */
  initialize: (settings: {
    /**
     * Whether currently logged in user has admin permissions or not.
     */
    isAdminUser: boolean;
    /**
     * Browser location pathname and hash to initialize the store to.
     * @example
     * ['/docs/getting-started', '#/configure/appearance/theme-editor']
     */
    location?: [pathname: string, hash?: string];
    /**
     * Project feature flags that are relevant to SuperHub in order to determine
     * whether it should be enabled or not.
     */
    projectFlags: Partial<Pick<ProjectClientSide['flags'], Flag.SUPERHUB | Flag.SUPERHUB_DEVELOPMENT>>;
    /**
     * Project subdomain.
     * @example my-project
     */
    subdomain: SuperHubStoreState['subdomain'];
    /**
     * Clean project version.
     * @example 1.0.0
     */
    version: SuperHubStoreState['version'];
  }) => void;

  /**
   * Resets state back to the initial default state.
   */
  reset: () => void;

  /**
   * Updates the current route we've currently navigated to with the provided
   * URL pathname and hash properties. Segments in each property are then parsed
   * and interpreted by our state.
   * @see ConnectSuperHubStoreToRouter
   */
  updateRoute: (pathname: string, hash?: string) => void;
}

export type SuperHubStore = SuperHubDocumentSlice &
  SuperHubEditorSlice &
  SuperHubSidebarSlice &
  SuperHubStoreAction &
  SuperHubStoreState;

const initialState: SuperHubStoreState = {
  apiBaseUrl: '',
  isEditing: false,
  isReady: false,
  isReferenceRoute: false,
  isSuperHub: false,
  isSuperHubAdmin: false,
  isSuperHubDevelopment: false,
  layout: 'default',
  routeSection: null,
  slug: null,
  subdomain: null,
  version: null,
};

/**
 * Vanilla store that contains all application-level state data required by
 * SuperHub. This store can be accessed and used anywhere. React components
 * should call `useSuperHubStore()` instead.
 * @example
 * import { superHubStore } from '@core/store';
 *
 * const isEditing = superHubStore.getState().isEditing;
 */
export const superHubStore = createStore<SuperHubStore>()(
  immer(
    devtools(
      (set, get, ...props) => {
        /**
         * Holds reference to the initial state so we can support resetting the
         * store back to this state when calling `reset()`.
         */
        const resetState = {
          ...initialState,
          ...createSuperHubDocumentSlice(set, get, ...props),
          ...createSuperHubEditorSlice(set, get, ...props),
          ...createSuperHubSidebarSlice(set, get, ...props),
        };

        return {
          ...resetState,

          getApiEndpoint: slug => {
            const { apiBaseUrl, routeSection } = get();
            if (!routeSection) {
              throw new Error('Route section is missing when getting API endpoint URL');
            }

            const apiType = {
              changelog: 'changelogs',
              docs: 'guides',
              reference: 'reference',
              page: 'custom_pages',
            }[routeSection];

            // Some sections are not versioned. So we strip away that segment
            // from the API url for those cases.
            const versionedTypes = ['guides', 'reference', 'custom_pages'];
            const baseUrl = versionedTypes.includes(apiType) ? apiBaseUrl : apiBaseUrl.split('/versions/')[0];
            return [baseUrl, apiType, slug].filter(Boolean).join('/');
          },

          initialize: settings => {
            const { projectFlags: flags, isAdminUser, location = [], subdomain, version } = settings;
            set(
              state => {
                state.apiBaseUrl = `/${subdomain}/api-next/v2/versions/${version}`;
                state.isReady = isClient;
                state.isSuperHub = !!flags.superHub;
                state.isSuperHubAdmin = state.isSuperHub && isAdminUser;
                state.isSuperHubDevelopment = state.isSuperHubAdmin && !!flags.superHubDevelopment;
                state.subdomain = subdomain;
                state.version = version;
              },
              false,
              actionLog('initialize', settings),
            );

            // Continue initialization based on the location route.
            const [pathname, hash] = location;
            if (pathname) {
              get().updateRoute(pathname, hash);
            }
          },

          reset: () => {
            set(resetState, false, actionLog('reset'));
            get().sidebar.updateCache();
          },

          updateRoute: (pathname, hash = '') => {
            // Match the same route paths from our superhub routers to parse out
            // route params in the same way.
            const match = matchPath<SuperHubRouteParams>(pathname, Object.values(SuperHubRoutePaths));
            const hashMatch = matchPath<SuperHubHashRouteParams>(
              hash.substring(1),
              Object.values(SuperHubHashRoutePaths),
            );

            const {
              params: { action = '', section = null, slug = null },
            } = match ?? { params: {} };
            const {
              params: { action: hashAction, section: hashSection = '' },
            } = hashMatch ?? { params: {} };

            set(
              state => {
                state.isEditing = ['create', 'update', 'compare'].includes(action);
                state.isReferenceRoute = ['api-definition', 'reference'].includes(section || '');
                state.routeSection = section;
                state.slug = slug;
                state.editor.isCreateNewPage = action === 'create';

                if (action === 'compare') {
                  state.layout = 'aside-standalone';
                } else if (section === 'reference' && hashAction === 'create' && hashSection === 'api-definition') {
                  state.layout = 'aside';
                } else if (hashAction === 'configure' || hashAction === 'content') {
                  state.layout = 'settings';
                } else if (hashAction === 'appearance') {
                  state.layout = 'settings-preview';
                } else if (['create', 'update'].includes(action)) {
                  state.layout = 'edit';
                  state.editor.isRawMode = false;
                } else {
                  state.layout = 'default';
                }

                if (['configure', 'content', 'appearance'].includes(hashAction ?? '')) {
                  // Always clear project store errors when re-routing.
                  projectStore.getState().resetSaveError();
                }

                if (state.isEditing && !slug) {
                  // When creating a new page, clear out the document.
                  state.document.data = null;
                }
              },
              false,
              actionLog('updateRoute', { pathname, hash }),
            );
          },
        };
      },
      { name: 'SuperHubStore' },
    ),
  ),
);

/**
 * Bound react hook to access our SuperHub store. Must be called within a React
 * component. To access the store outside of React, use `superHubStore` instead.
 * @example
 * import { useSuperHubStore } from '@core/store';
 *
 * function Component() {
 *   const isEditing = useSuperHubStore(s => s.isEditing);
 * }
 */
export const useSuperHubStore = createBoundedUseStore(superHubStore);

export * from './ConnectSuperHubStoreToRouter';
export * from './InitializeSuperHubStore';
export * from './Document';
export * from './Editor';
export * from './Sidebar';
