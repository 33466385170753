import type { $TSFixMe } from '@readme/iso';

import React, { useContext, useEffect } from 'react';

import { ProjectContext } from '@core/context';
import { useProjectStore } from '@core/store';

import liveUpdateHub2CSS from './liveUpdateHub2CSS';

/**
 * Use this component for logic that needs to modify the document outside of the Hub's react root element
 * based on changes to the project store.
 *
 * Most updates should be handled by Hub components reactively updating from the project
 * store state, but there are some cases where we need to make updates to the Document DOM
 * outside of the Hub's root element.  For example updating the custom CSS or swapping a favicon.
 */
export function ConnectProjectStoreToDocument({ children }: { children?: React.ReactNode }) {
  // @todo: Using ProjectContext is a temporary solution until we can get the parent data in the apiV2 project representation
  const { project } = useContext(ProjectContext) as { project: $TSFixMe };
  const parentCSS = project?.parent?.appearance?.stylesheet_hub2;

  const projectCSS = useProjectStore(s => s.data?.appearance?.custom_code?.css);

  useEffect(() => {
    liveUpdateHub2CSS([parentCSS, projectCSS].join('') || '');
  }, [parentCSS, projectCSS]);

  return <>{children}</>;
}
