import type { SuperHubStore } from '..';
import type { ErrorType } from '@readme/api/src/core/legacy_mappings/error';
import type { CreateChangelogType, ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type {
  ReadCustomBlockGitCollectionType,
  ReadCustomBlockGitType,
} from '@readme/api/src/mappings/customblock/types';
import type { CreateCustomPageType, CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { CreateGuideType, ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { CreateReferenceType, ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';
import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { WritableDeep } from 'type-fest';
import type { StateCreator } from 'zustand';

import { mutate } from 'swr';

import type useReadmeApi from '@core/hooks/useReadmeApi';
import { fetcher } from '@core/hooks/useReadmeApi';
import { actionLog, isClient } from '@core/store/util';
import type { HTTPError } from '@core/utils/types/errors';

import { isChangelog, isCustomPage, isGuidesPage, isReferencePage } from './util';

export type SuperHubDocumentData =
  | CustomPageReadType['data']
  | ReadChangelogType['data']
  | ReadGuideType['data']
  | ReadReferenceType['data'];
export type SuperHubGuideReferencePage = ReadGuideType['data'] | ReadReferenceType['data'];

/**
 * Request payload type for "create" endpoints.
 */
export type SuperHubCreateDocumentData =
  | CreateChangelogType
  | CreateCustomPageType
  | CreateGuideType
  | CreateReferenceType;

/**
 * Response document type from "create" API endpoints.
 */
type CreateDocumentResponse<CreateDocument> = CreateDocument extends CreateChangelogType
  ? ReadChangelogType
  : CreateDocument extends CreateCustomPageType
    ? CustomPageReadType
    : CreateDocument extends CreateGuideType
      ? ReadGuideType
      : ReadReferenceType;

interface SuperHubDocumentSliceState {
  /**
   * Collection of custom blocks that are used in the current document.
   * This data is used to hydrate the MarkdownEditor with a document's initial custom block data
   * as well as to provide RDMD with the necessary custom block values when parsing the document.
   */
  customBlocks: ReadCustomBlockGitCollectionType['data'];

  /**
   * Primary data source for the current page.
   */
  data: SuperHubDocumentData | null;

  /**
   * Whether document data is currently being fetched via API.
   */
  isLoading: boolean;

  /**
   * Indicates this slice has been initialized to its beginning state by the
   * `initialize()` action and lets connected subscribers know that it is ready
   * for consumption.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Indicates whether a document save request is currently pending.
   */
  isSaving: boolean;

  /**
   * Error object that is set when a document save request fails.
   */
  saveError: ErrorType | null;

  /**
   * Holds reference to the SWR request key that was used to fetch document
   * data from the API. This key can then be used by SWR's `mutate()` function
   * to update cached data to optimistically reflect a changed document while
   * a mutation request is in flight.
   * @see https://swr.vercel.app/docs/mutation
   */
  swrKey: ReturnType<typeof useReadmeApi>['swrKey'];
}

interface SuperHubDocumentSliceAction {
  /**
   * Creates a new page document inside the provided category.
   * @todo the typing of `newDocument` should be updated to a union of CreateGuideType and
   * CreateReferencePageType once we have the APIV2 typings for the reference section.
   */
  createDocument<CreateDocument extends SuperHubCreateDocumentData>(
    newDocument: CreateDocument,
  ): Promise<CreateDocumentResponse<CreateDocument>['data']>;

  /**
   * Returns a changelog document if one exists and is loaded.
   */
  getChangelogData: () => ReadChangelogType['data'] | null;

  /**
   * Returns a dictionary of custom blocks that are used in the current document.
   * This data is passed to RDMD when we're parsing the current document's markdown.
   */
  getCustomBlocksDictionary: () => Record<
    ReadCustomBlockGitType['data']['tag'],
    ReadCustomBlockGitType['data']['source']
  >;

  /**
   * Returns a custom page document if one exists and is loaded.
   */
  getCustomPageData: () => CustomPageReadType['data'] | null;

  /**
   * Returns a guides page document if one exists and is loaded.
   */
  getGuidesPageData: () => ReadGuideType['data'] | null;

  /**
   * Returns a reference page document if one exists and is loaded.
   */
  getReferencePageData: () => ReadReferenceType['data'] | null;

  /**
   * Updates the state with data and loading states. Typically called when
   * initializing this slice with SSR data or when hydrating it from an API.
   */
  initialize: (
    payload: Pick<SuperHubDocumentSliceState, 'data' | 'isLoading'> & {
      customBlocks?: SuperHubDocumentSliceState['customBlocks'];
      swrKey?: SuperHubDocumentSliceState['swrKey'];
    },
  ) => void;

  /**
   * Create or update a `customBlocks` item in the current state.
   */
  updateCustomBlock: (block: ReadCustomBlockGitType['data']) => void;

  /**
   * Persist changes of the current document to the API.
   */
  updateDocument: (nextDocument: Partial<SuperHubDocumentData>) => Promise<SuperHubDocumentData>;
}

export interface SuperHubDocumentSlice {
  /**
   * State slice containing fields and actions that are relevant when viewing
   * and editing page documents in SuperHub.
   */
  document: SuperHubDocumentSliceAction & SuperHubDocumentSliceState;
}

const initialState: SuperHubDocumentSliceState = {
  customBlocks: [],
  data: null,
  isLoading: false,
  isReady: false,
  isSaving: false,
  saveError: null,
  swrKey: null,
};

/**
 * Creates a state slice containing all fields related to our page document.
 */
export const createSuperHubDocumentSlice: StateCreator<
  SuperHubDocumentSlice & SuperHubStore,
  [['zustand/immer', never], ['zustand/devtools', never]],
  [],
  SuperHubDocumentSlice
> = (set, get) => ({
  document: {
    ...initialState,

    createDocument: async newDocument => {
      set(
        state => {
          state.document.isSaving = true;
        },
        false,
        actionLog('document.createDocument.pending', newDocument),
      );

      try {
        const { data } = await fetcher<CreateDocumentResponse<typeof newDocument>>(get().getApiEndpoint(), {
          body: JSON.stringify(newDocument),
          method: 'POST',
        });

        set(
          state => {
            state.document.data = data;
            state.document.isSaving = false;
          },
          false,
          actionLog('document.createDocument.fulfilled', newDocument),
        );

        // Revalidate the sidebar to include this newly created page.
        mutate<GitSidebarCategory[]>(get().sidebar.swrKey);
        return data;
      } catch (error) {
        const { info } = error as HTTPError;
        set(
          state => {
            state.document.isSaving = false;
            state.document.saveError = info as ErrorType;
          },
          false,
          actionLog('document.createDocument.rejected', newDocument),
        );
        throw error;
      }
    },

    getChangelogData: () => {
      const data = get()?.document.data;
      return isChangelog(data) ? data : null;
    },

    getCustomBlocksDictionary: () => {
      return get().document.customBlocks.reduce((acc, block) => {
        acc[block.tag] = block.source;
        return acc;
      }, {});
    },

    getCustomPageData: () => {
      const data = get()?.document.data;
      return isCustomPage(data) ? data : null;
    },

    getGuidesPageData: () => {
      const data = get().document.data;
      return isGuidesPage(data) ? data : null;
    },

    getReferencePageData: () => {
      const data = get()?.document.data;
      return isReferencePage(data) ? data : null;
    },

    initialize: payload => {
      const { data, isLoading, swrKey, customBlocks } = payload;

      const nextData = {
        data: data ?? get().document.data,
        customBlocks: customBlocks ?? get().document.customBlocks,
        isLoading,
      };

      // Only continue with a state update if there are changed values. This
      // quiets down the redux devtools action logs to only contain actions
      // that contain differences.
      const hasChanges = Object.entries(nextData).some(([key, value]) => {
        return value !== get().document[key];
      });
      if (!hasChanges) return;

      set(
        state => {
          const writableSwrKey = (swrKey ?? get().document.swrKey) as WritableDeep<typeof swrKey>;
          state.document = {
            ...state.document,
            ...nextData,
            swrKey: writableSwrKey ?? null,
          };

          // When running on the server, we must avoid marking this store as
          // "ready" to ensure it continues receiving updates until it gets
          // initialized on the client's first render.
          state.document.isReady = isClient;
        },
        false,
        actionLog('document.initialize', payload),
      );
    },

    updateCustomBlock: block => {
      set(
        state => {
          const customBlocks = state.document.customBlocks;
          const index = customBlocks.findIndex(({ tag }) => tag === block.tag);
          if (index === -1) {
            state.document.customBlocks.push(block);
          } else {
            state.document.customBlocks[index] = block;
          }
        },
        false,
        actionLog('document.updateCustomBlock', block),
      );
    },

    updateDocument: async nextDocument => {
      set(
        state => {
          state.document.isSaving = true;
        },
        false,
        actionLog('document.updateDocument.pending', nextDocument),
      );

      const { data: document } = get().document;
      if (!document) {
        throw new Error('Missing required page document');
      }

      try {
        const { data } = await fetcher<{ data: SuperHubDocumentData }>(get().getApiEndpoint(document.slug), {
          body: JSON.stringify(nextDocument),
          method: 'PATCH',
        });

        set(
          state => {
            state.document.data = data;
            state.document.isSaving = false;
          },
          false,
          actionLog('document.updateDocument.fulfilled', nextDocument),
        );

        // Update page document SWR cache to the latest changes.
        mutate<{ data: typeof data }>(get().document.swrKey, { data }, { revalidate: false });

        // Revalidate the sidebar to include this newly created page.
        mutate<GitSidebarCategory[]>(get().sidebar.swrKey);
        return data;
      } catch (error) {
        const { info } = error as HTTPError;
        set(
          state => {
            state.document.isSaving = false;
            state.document.saveError = info as ErrorType;
          },
          false,
          actionLog('document.updateDocument.rejected', nextDocument),
        );
        throw error;
      }
    },
  },
});

export * from './ConnectSuperHubDocumentToApi';
export * from './InitializeSuperHubDocument';
