import type { Options } from '.';

import useTimezone from '../useTimezone';

import useReadmeApi from '.';

/**
 * A wrapper around `useReadmeApi` for /api/realtime requests that adds 'x-timezone'
 * header to all requests. This client-side timezone is used to assign the correct
 * timezone to the data returned from the Metrics API.
 */
function useReadmeApiRealtime<Data>(path: string | null, options: Options = {}, isReady = true) {
  let timezone = useTimezone();

  let isUTCFallback = false;

  // If timezone doesn't resolve for some reason, we don't want requests to fail
  // We'll add a custom fallback to UTC in this scenario
  if (!timezone) {
    timezone = 'UTC';
    isUTCFallback = true;
  }

  const opts = {
    ...options,
    // Always include x-timezone in headers for all Metrics requests
    headers: {
      ...options?.headers,
      'x-timezone': timezone,
    },
  };

  const isReadyToFetch = isReady && (timezone !== 'UTC' || isUTCFallback);

  return useReadmeApi<Data>(path, { ...opts }, isReadyToFetch);
}

export default useReadmeApiRealtime;
