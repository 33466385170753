import type { SuperHubStore } from '..';
import type { StateCreator } from 'zustand';

import { actionLog } from '@core/store/util';

interface SuperHubEditorSliceState {
  /**
   * Indicates whether we are currently attempting to create a new page.
   */
  isCreateNewPage: boolean;

  /**
   * Indicates whether we are currently editing a "raw" markdown or using
   * the MarkdownEditor component UI.
   */
  isRawMode: boolean;

  /**
   * Indicates whether we are currently editing "realtime" pages on the
   * Developer Dashboard. These would be pages like
   * `/reference/intro/getting-started` or `/reference/intro/authentication`.
   */
  isRealTime: boolean;
}

interface SuperHubEditorSliceAction {
  /**
   * Sets the isRawMode state.
   */
  updateRawMode: (isRawMode: boolean) => void;
}

export interface SuperHubEditorSlice {
  /**
   * State slice containing fields and actions that are relevant when editing
   * doc pages in SuperHub. This slice includes data about the page document
   * (body, excerpt, metadata, slug, etc) in addition to sidebar nav data.
   */
  editor: SuperHubEditorSliceAction & SuperHubEditorSliceState;
}

const initialState: SuperHubEditorSliceState = {
  isCreateNewPage: false,
  isRawMode: false,
  isRealTime: false,
};

/**
 * SuperHubEditor state slice containing fields related to editing doc pages.
 */
export const createSuperHubEditorSlice: StateCreator<
  SuperHubEditorSlice & SuperHubStore,
  [['zustand/immer', never], ['zustand/devtools', never]],
  [],
  SuperHubEditorSlice
> = set => ({
  editor: {
    ...initialState,

    updateRawMode: isRawMode => {
      set(
        state => {
          state.editor.isRawMode = isRawMode;
        },
        false,
        actionLog('editor.updateRawMode', isRawMode),
      );
    },
  },
});
