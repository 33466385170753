import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { PageDocument } from '@readme/backend/models/page/types';

/**
 * Normalize for children pages between Git and Mongo pages.
 */
export function getChildrenPages(page: GitSidebarPage | PageDocument): GitSidebarPage[] | PageDocument[] {
  if ('pages' in page) {
    return page.pages;
  }
  return page.children as PageDocument[];
}

/**
 * Check if a page has visible, children pages.
 */
export function hasChildrenPages(childrenPages: GitSidebarPage[] | PageDocument[]): boolean {
  return (childrenPages || []).filter(child => !child.hidden).length > 0;
}

/**
 * Detect empty parent pages are pages with no body content that contain at least a single child.
 */
export function isEmptyParentPage(page: GitSidebarPage | PageDocument): boolean {
  return (
    ('isReference' in page && page.isReference
      ? // Only pages that are neither endpoints nor realtime pages should be considered empty
        page.type !== 'endpoint' && page.pageType !== 'RealtimePage'
      : page.type === 'basic') &&
    page.isBodyEmpty &&
    hasChildrenPages(getChildrenPages(page))
  );
}
