import type { ProjectSchema, ProjectDocument } from '@readme/backend/models/project/types';

import { daysRemaining } from '../utils/date';

export type PlanConfig = PlanBaseConfig & PlanFeatures;
interface PlanBaseConfig {
  cost: number;
  createdAt: Date;
  endDate?: Date;
  is_active: boolean;

  /** What is the maximum amount of admins that this plan allows? */
  members: number;
  name: string;
}

export interface PlanFeatures {
  /**
   * Can this plan have users classified as a project admin?
   */
  admins: boolean;

  /** Does this plan have access to our API? */
  api_access: boolean;

  /** Does this plan have access to changelogs? */
  changelogs: boolean;

  /** Does this plan have access to set a custom domain? */
  custom_domain: boolean;

  /** Does this plan have access to custom pages? */
  custom_pages: boolean;
  doc_metrics: boolean;

  /**
   * Can this plan create error pages?
   * @deprecated Error pages are no longer able to be created.
   */
  errors: boolean;

  /** Can this plan set custom HTML in their header? */
  extra_html: boolean;

  /** Can this plan use glossaries */
  glossary: boolean;

  /** Can this plan create guides? */
  guides: boolean;

  /** Can this plan set up an integration with a health check service? */
  healthCheck: boolean;

  /** Can this plan set custom HTML in their landing page? */
  html: boolean;

  /** Can this plan have custom JS? */
  javascript: boolean;
  jwt: boolean;

  /** Can this plan create a global landing page (GLP)? */
  landing_page: boolean;

  /** Can this plan export metrics (CSV downloads)? */
  metricsExports: boolean;

  /** Can this plan export owlbot questions (CSV downloads)? */
  owlbotExports: boolean;

  /** Can this plan password-protect their Hub? */
  password: boolean;

  /** Can this plan export pages as a PDF? */
  pdf: boolean;

  /** Can this plan upload OpenAPI definitions? */
  reference: boolean;

  /** Can this create and update Reusable Content? */
  reusableContent: boolean;

  /** Can this plan have custom CSS? */
  stylesheet: boolean;
  usability: boolean;

  /** Can this plan interact with backend services that use Clearbit for user introspection? */
  user_analytics: boolean;

  /** Does this plan have access to user variables? */
  variables: boolean;

  /** The number of versions that this plan can hold. */
  versions: number;

  /** Can this plan hide the ReadMe branding on their hub? */
  whitelabel: boolean;
}

/**
 * * IF YOU MAKE CHANGES TO THIS CONFIG MAKE SURE TO UPDATE BOTH THE LEGACY CONFIGS TOO.
 *
 * @see {@readme/legacy-angular-tooling/shared.js}
 * @see {@readme/server-shared/shared.js}
 */
const permissions: Record<string, Partial<PlanFeatures> & PlanBaseConfig> = {
  free: {
    // NOTE: If we add this tier back in, make sure we only
    // allow trial accounts to have teammates (see getAllRegularProjects)
    name: 'Inactive Project',
    members: 0,
    is_active: true,
    cost: 0,
    createdAt: new Date('January 1, 2010'),
    endDate: undefined,

    versions: 1,
    api_access: true,
    custom_domain: false,
    custom_pages: false,
    whitelabel: false,
    errors: false,
    password: false,
    glossary: false,
    landing_page: false,

    usability: false,
    stylesheet: false,
    javascript: false,
    html: false,
    extra_html: false,
    user_analytics: false,
    doc_metrics: false,

    changelogs: false,
    reference: true,
    guides: false,

    admins: false,
    jwt: true,
    metricsExports: false,
    owlbotExports: false,
    // enabled during free trial, disabled at freelaunch
    reusableContent: true,
  },
  startup: {
    name: 'Startup',
    members: 10,
    is_active: true,
    cost: 59,
    createdAt: new Date('January 1, 2010'),
    endDate: new Date('August 28, 2018'),

    versions: 10000,
    api_access: true,
    custom_domain: true,
    custom_pages: true,
    whitelabel: false,
    errors: false,
    password: true,
    glossary: true,
    landing_page: true,

    usability: true,
    stylesheet: true,
    javascript: false,
    html: true,
    extra_html: false,
    user_analytics: false,
    doc_metrics: true,
    variables: true,
    jwt: true,

    changelogs: true,
    reference: true,
    guides: true,

    admins: true,
    metricsExports: false,
    owlbotExports: false,
    reusableContent: false,
  },
  business: {
    name: 'Business',
    is_active: true,
    members: 1000,
    cost: 199,
    createdAt: new Date('January 1, 2010'),
    endDate: new Date('August 28, 2018'),

    versions: 10000,
    api_access: true,
    custom_domain: true,
    custom_pages: true,
    whitelabel: true,
    errors: true,
    password: true,
    glossary: true,
    landing_page: true,
    pdf: true,

    usability: true,
    stylesheet: true,
    javascript: true,
    html: true,
    extra_html: true,
    user_analytics: true,
    doc_metrics: true,
    variables: true,
    jwt: true,

    changelogs: true,
    reference: true,
    guides: true,

    admins: true,
    healthCheck: true,

    metricsExports: true,
    owlbotExports: true,
    reusableContent: true,
  },
};

permissions.freelaunch = JSON.parse(JSON.stringify(permissions.free));
permissions.freelaunch.cost = 0;
permissions.freelaunch.stylesheet = false;
permissions.freelaunch.createdAt = new Date('May 4, 2020');
permissions.freelaunch.endDate = undefined;
permissions.freelaunch.members = 5;
permissions.freelaunch.admins = true;
permissions.freelaunch.versions = 3;
permissions.freelaunch.name = 'Free';
permissions.freelaunch.reusableContent = false;

permissions.startup2018 = JSON.parse(JSON.stringify(permissions.startup));
permissions.startup2018.cost = 99;
permissions.startup2018.stylesheet = false;
permissions.startup2018.createdAt = new Date('August 28, 2018');
permissions.startup2018.endDate = undefined;

permissions['startup-annual-2024'] = JSON.parse(JSON.stringify(permissions.startup));
permissions['startup-annual-2024'].cost = 948;
permissions['startup-annual-2024'].stylesheet = false;
permissions['startup-annual-2024'].createdAt = new Date('March 27, 2024');
permissions['startup-annual-2024'].endDate = undefined;

permissions.business2018 = JSON.parse(JSON.stringify(permissions.business));
permissions.business2018.cost = 399;
permissions.business2018.javascript = false;
permissions.business2018.createdAt = new Date('August 28, 2018');
permissions.business2018.endDate = undefined;
permissions.business2018.metricsExports = true;
permissions.business2018.owlbotExports = true;

permissions['business-annual-2024'] = JSON.parse(JSON.stringify(permissions.business));
permissions['business-annual-2024'].cost = 4188;
permissions['business-annual-2024'].javascript = false;
permissions['business-annual-2024'].createdAt = new Date('March 27, 2024');
permissions['business-annual-2024'].endDate = undefined;
permissions['business-annual-2024'].metricsExports = true;
permissions['business-annual-2024'].owlbotExports = true;

permissions.enterprise = JSON.parse(JSON.stringify(permissions.business));
permissions.enterprise.cost = 0;
permissions.enterprise.members = 10000;
permissions.enterprise.javascript = true;
permissions.enterprise.name = 'Enterprise';
permissions.enterprise.createdAt = new Date('January 1, 2010');
permissions.enterprise.endDate = undefined;
permissions.enterprise.metricsExports = true;
permissions.enterprise.owlbotExports = true;

permissions.opensource = JSON.parse(JSON.stringify(permissions.startup));
permissions.opensource.name = 'Open Source';
permissions.opensource.is_active = true;
permissions.opensource.password = false;
permissions.opensource.custom_domain = false;
permissions.opensource.cost = 0;
permissions.opensource.createdAt = new Date('January 1, 2010');
permissions.opensource.endDate = undefined;

/**
 * Retrieve the config for a specific plan feature.
 *
 */
export function getPlanFeature<T extends keyof PlanConfig>(plan: ProjectSchema['plan'], feature: T) {
  return permissions?.[plan]?.[feature];
}

export enum StripePlanInterval {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  YEAR = 'year',
}

export enum PLAN_NAME {
  BUSINESS = 'business',
  BUSINESS_2018 = 'business2018',
  BUSINESS_ANNUAL_2024 = 'business-annual-2024',
  ENTERPRISE = 'enterprise',
  FREE = 'free', // trial
  FREELAUNCH = 'freelaunch', // free post-trial period
  OPENSOURCE = 'opensource',
  STARTUP = 'startup',
  STARTUP_2018 = 'startup2018',
  STARTUP_ANNUAL_2024 = 'startup-annual-2024',
}

export enum PlanCadence {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const PlanCadenceArray = Object.values(PlanCadence);

export enum PlanType {
  FREE = 0 /* freelaunch */,
  TRIAL = 1 /* free */,
  STARTUP = 2 /* startup (legacy) or startup2018 or startup-annual-2024 or opensource */,
  BUSINESS = 3 /* business (legacy) or business2018 or business-annual-2024 */,
  ENTERPRISE = 4 /* enterprise */,
  DEV_DASH = 5 /* dev dash metrics metered billing */,
}

export const planNameToType = {
  [PLAN_NAME.FREELAUNCH]: PlanType.FREE,
  [PLAN_NAME.FREE]: PlanType.TRIAL,
  [PLAN_NAME.OPENSOURCE]: PlanType.STARTUP,
  [PLAN_NAME.STARTUP]: PlanType.STARTUP,
  [PLAN_NAME.STARTUP_2018]: PlanType.STARTUP,
  [PLAN_NAME.BUSINESS]: PlanType.BUSINESS,
  [PLAN_NAME.BUSINESS_2018]: PlanType.BUSINESS,
  [PLAN_NAME.ENTERPRISE]: PlanType.ENTERPRISE,
  [PLAN_NAME.STARTUP_ANNUAL_2024]: PlanType.STARTUP,
  [PLAN_NAME.BUSINESS_ANNUAL_2024]: PlanType.BUSINESS,
};

export const planNameToCadence = {
  '': PlanCadence.MONTHLY, // Default to monthly
  [PLAN_NAME.FREELAUNCH]: PlanCadence.MONTHLY,
  [PLAN_NAME.FREE]: PlanCadence.MONTHLY,
  [PLAN_NAME.OPENSOURCE]: PlanCadence.MONTHLY,
  [PLAN_NAME.STARTUP]: PlanCadence.MONTHLY,
  [PLAN_NAME.STARTUP_2018]: PlanCadence.MONTHLY,
  [PLAN_NAME.BUSINESS]: PlanCadence.MONTHLY,
  [PLAN_NAME.BUSINESS_2018]: PlanCadence.MONTHLY,
  [PLAN_NAME.ENTERPRISE]: PlanCadence.MONTHLY,
  [PLAN_NAME.STARTUP_ANNUAL_2024]: PlanCadence.YEARLY,
  [PLAN_NAME.BUSINESS_ANNUAL_2024]: PlanCadence.YEARLY,
};

export const PlanNameArray = Object.values(PLAN_NAME);

export enum ADD_ONS {
  DEV_DASH_METERED_2024 = 'dev-dash-metered-2024',
  METRICS = 'metrics',
  OWLBOT = 'owlbot',
}

export enum PLANS_AND_ADD_ONS {
  // project plans
  BUSINESS = 'business',
  BUSINESS_2018 = 'business2018',
  BUSINESS_ANNUAL_2024 = 'business-annual-2024',
  DEV_DASH_METERED_2024 = 'dev-dash-metered-2024',
  ENTERPRISE = 'enterprise',
  FREE = 'free',
  // trial
  FREELAUNCH = 'freelaunch',
  STARTUP = 'startup',
  STARTUP_2018 = 'startup2018',
  STARTUP_ANNUAL_2024 = 'startup-annual-2024',
  // add on plans
  // eslint-disable-next-line typescript-sort-keys/string-enum
  METRICS = 'metrics', // old metrics plan (pre-2024, not metered)
  // free post-trial period
  OPENSOURCE = 'opensource',
  OWLBOT = 'owlbot',
}

export enum STRIPE_PLANS {
  // project plans
  BUSINESS = 'business',
  BUSINESS_2018 = 'business2018',
  BUSINESS_ANNUAL_2024 = 'business-annual-2024',
  DEV_DASH_METERED_2024 = 'dev-dash-metered-2024',
  FREE = 'free',
  // trial
  FREELAUNCH = 'freelaunch',
  STARTUP = 'startup',
  STARTUP_2018 = 'startup2018',
  STARTUP_ANNUAL_2024 = 'startup-annual-2024',
  // eslint-disable-next-line typescript-sort-keys/string-enum
  METRICS = 'metrics', // old metrics plan (pre-2024, not metered)
  OWLBOT = 'owlbot',
}

export function getProjectPlan(project: ProjectDocument) {
  if (project.flags.enterprise) return 'enterprise';
  if (project.planOverride) return project.planOverride;
  // for delinquent projects, we do this to avoid their hubs going offline
  if (project.planStatus === 'unpaid' || (!project.is_active && (!project.plan || project.plan === 'free')))
    return 'business2018';
  return project.plan;
}

function getPermission(plan: string, permission?: string) {
  if (permission) {
    return permissions[plan][permission];
  } else if (plan) {
    return permissions[plan];
  }

  return permissions;
}

export function getProjectPermission(project: ProjectDocument, permission?: string) {
  if (!permission) {
    return getPermission(getProjectPlan(project));
  }
  return getPermission(getProjectPlan(project), permission);
}

export function isCustomDomainAllowed(project: ProjectDocument) {
  // If project is trial and the grace period is not expired allow them to use custom domains. This is will be the state when projects transfer owners.
  const isGracePeriodExpired = !project.gracePeriod?.enabled || daysRemaining(project.gracePeriod?.endsAt) === 0;
  const isTrial = project.plan === 'free';
  if (isTrial && !isGracePeriodExpired) return true;

  return getProjectPermission(project, 'custom_domain');
}

export function getProjectBaseUrl(env: string, siteDomain: string, project: ProjectDocument, staging = false) {
  const isStagingEnabled = p => {
    const topLevelProject = p.childrenProjects?.length ? p : p._parent;
    if (!topLevelProject) return false;
    return topLevelProject.flags.staging;
  };

  if (env === 'development' && (!isStagingEnabled(project) || staging)) {
    return `http://${project.subdomain}.readme.local:3000/`;
  }

  const renderNotProd = new Set(['pr', 'next', 'stage']);

  const customDomain = project._parent ? project._parent.custom_domain : project.custom_domain;
  // go to custom domain
  if (renderNotProd.has(env) && isStagingEnabled(project) && !staging) {
    return `https://${customDomain}/`;
  }

  if (env === 'development' && !staging) {
    return `http://${customDomain}:3000/`;
  }

  if (customDomain && isCustomDomainAllowed(project) && !staging && !renderNotProd.has(env)) {
    return `https://${customDomain}/`;
  }

  return `https://${project.subdomain}.${siteDomain}/`;
}

export function getProjectHomeUrl(
  env: string,
  siteDomain: string,
  project: ProjectDocument,
  ...path: (boolean | string)[]
) {
  let baseUrl = getProjectBaseUrl(env, siteDomain, project);

  if (path.length === 1 && path[0] === false) {
    // eslint-disable-next-line no-param-reassign
    path = [];
  }

  // Child projects should have themselves
  // (either their subpath or their subdomain) in their home URL.
  if (project._parent && path[0] !== project.subpath && path[0] !== project.subdomain) {
    baseUrl = getProjectBaseUrl(env, siteDomain, project._parent);
    path.unshift(project.subpath || project.subdomain);
  }

  return baseUrl + path.join('/').replace(/^\//, '');
}
