import type { ForwardedRef } from 'react';

import React, { Children, forwardRef, memo } from 'react';
import { Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';
import Spinner from '@ui/Spinner';

import './style.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  bem?: Record<string, boolean | number | string> | string | [[string]];
  /**
   * Rounds the outer edges to create a pill-shaped or circular button depending
   * on whether the content contains text or a single icon/character.
   */
  circular?: boolean;
  /**
   * Disables the button from being interacted with.
   */
  disabled?: boolean;
  /**
   * Renders a button with a trailing chevron-down icon that is a convenience to
   * then use it as a dropdown control trigger.
   */
  dropdown?: boolean;
  /**
   * Spans the full width of the parent container. Useful when rendering buttons
   * in narrower-width containers such as mobile viewports.
   */
  fullWidth?: boolean;
  /**
   * Gives no contrast from the page background with an emphasized hover state.
   */
  ghost?: boolean;
  /**
   * When provided, renders an anchor element that looks like a button and
   * navigates to the URL when clicked on.
   */
  href?: string;
  is?: React.ElementType;
  /**
   * Default value is `primary` which is used for primary CTAs, typically at
   * most one on a given form.
   */
  kind?: 'contrast' | 'destructive' | 'minimum' | 'primary' | 'secondary' | 'success' | 'warning';
  /**
   * Used when UI should look like an anchor link but behave like an accesssible
   * button. Only respects the kind prop for text color. Font size is inherited
   * from the parent element.
   */
  link?: boolean;
  /**
   * Renders the button in a "loading" state with a subtle animation. Useful
   * when a form submission is in flight and pending a response.
   */
  loading?: boolean | string;
  /**
   * Gives subtle contrast from the page background.
   */
  outline?: boolean;
  rel?: string;
  shift?: 'center' | 'end' | 'start';
  /**
   * Default size is `md`.
   */
  size?: 'lg' | 'md' | 'sm' | 'xs';
  /**
   * Only relevant when either `href` or `to` props are provided to render an
   * anchor element instead of a button. Specifies where to open the linked
   * document, e.g. `_blank`, `_parent`.
   */
  target?: string;
  /**
   * No contrast from the page background with a subtle hover state.
   */
  text?: boolean;
  /**
   * When provided, renders a React Router `Link` element that navigates the
   * router to the specified path.
   */
  to?: string;
  /**
   * Default value is `button`, which won't submit forms when clicked.
   */
  type?: 'button' | 'reset' | 'submit';
};

function Button(
  {
    bem,
    children,
    circular,
    className,
    disabled,
    dropdown,
    fullWidth = false,
    href,
    is,
    kind = 'primary',
    ghost,
    link,
    loading,
    outline,
    size = 'md',
    text,
    to,
    type = 'button',
    ...attrs
  }: ButtonProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const Tag = is || (href ? 'a' : to ? Link : 'button');
  const classyBem = useClassy({}, 'Button');
  const arrayChildren = Children.toArray(children);
  const bemPropVariant = typeof bem === 'object' ? Object.keys(bem)[0] : null;
  const buttonClasses = classyBem(
    '&',
    bemPropVariant && `_${bemPropVariant}`,
    !link && `_${size}`,
    className,
    circular && '_circular',
    fullWidth && '_full-width',
    ghost && `_${kind}_ghost`,
    kind && `_${kind}`,
    link && `_${kind}_link`,
    loading && '_loading',
    typeof loading === 'string' && '_loading_withText',
    outline && `_${kind}_outline`,
    text && `_${kind}_text`,
  );

  return (
    <Tag
      ref={ref}
      {...(href && { href })}
      {...(to && { to })}
      {...attrs}
      className={buttonClasses}
      disabled={loading || disabled}
      type={href ? undefined : type}
    >
      {!!loading && <Spinner className="Button-spinner" size={undefined} />}
      {typeof loading === 'string' && loading}
      {typeof loading !== 'string' &&
        arrayChildren.map(child =>
          // If there are multiple children provided, wrap any text nodes with a span so the flex gap is applied
          (arrayChildren.length > 1 && typeof child === 'string') || (dropdown && typeof child === 'string') ? (
            <span key={`1_${child}`} className="Button-label">
              {child}
            </span>
          ) : (
            child
          ),
        )}
      {!!dropdown && <Icon aria-label="Chevron down" name="chevron-down" />}
    </Tag>
  );
}
export default memo(forwardRef(Button));
