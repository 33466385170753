import type { ReferenceStore } from '..';
import type { AuthForHAR } from '@readme/oas-to-har/lib/types';
import type Oas from 'oas';
import type { StateCreator } from 'zustand';

import { getGroupNameById } from '@readme/iso';
// eslint-disable-next-line readme-internal/no-restricted-imports
import { maskCredential } from '@readme/server-shared/metrics/mask-credential';

import type { VariablesContextValue } from '@core/context';
import { actionLog } from '@core/store/util';

export interface ReferenceAuthSliceState {
  /**
   * An object containing the currently populated authentication credentials
   * for the current operation(?)
   */
  auth: AuthForHAR;

  /**
   * For logged in users, this is the currently selected group (e.g., API credentials)
   */
  group?: string;

  /**
   * For logged in users, this is the name of the currently selected group
   */
  groupName?: boolean | string;

  /**
   * For logged in users, this array contains all groups (e.g., API credentials) available
   * for the current user.
   */
  groups?: {
    id: string;
    name: number | string;
  }[];

  /**
   * An hashed version of the group, which is stored in the user's local storage.
   */
  hashedGroup?: string;

  /**
   * Is the current user logged in?
   */
  isGroupLoggedIn: boolean;

  /**
   * For operations that contain multiple forms of auth, this array contains the ones that are currently selected.
   * The strings in this array correspond to the keys of the `auth` object.
   */
  selectedAuth: string[];
}

export interface ReferenceAuthSliceActions {
  /**
   * Initializes the store. This generally runs every time a new endpoint is selected.
   */
  initialize: (
    opts: Pick<ReferenceAuthSliceState, 'auth' | 'group' | 'groupName' | 'groups' | 'isGroupLoggedIn' | 'selectedAuth'>,
  ) => void;

  /**
   * Updates the `auth` object.
   * By default, the auth passed in is prioritized over the existing auth object.
   */
  updateAuth: (newAuth: ReferenceAuthSliceState['auth'], prioritizeNewAuth?: boolean) => void;

  /**
   * Updates the `group` selection.
   */
  updateGroup: ({
    apiDefinition,
    groupId,
    user,
  }: {
    apiDefinition: Oas;
    groupId: ReferenceAuthSliceState['group'];
    user: VariablesContextValue['user'];
  }) => void;

  /**
   * Updates the `selectedAuth` array.
   */
  updateSelectedAuth: (nextSelectedAuth: ReferenceAuthSliceState['selectedAuth']) => void;
}

export interface ReferenceAuthSlice {
  /**
   * State slice containing fields and actions that are relevant when reading/writing
   * auth data in the Reference. This slice includes data about the logged in user.
   */
  auth: ReferenceAuthSliceActions & ReferenceAuthSliceState;
}

export const initialState: ReferenceAuthSliceState = {
  auth: {},
  selectedAuth: [],
  isGroupLoggedIn: false,
};

/**
 * Auth state slice containing fields related to API authentication.
 */
export const createReferenceAuthSlice: StateCreator<
  ReferenceAuthSlice & ReferenceStore,
  [['zustand/immer', never], ['zustand/devtools', never]],
  [],
  ReferenceAuthSlice
> = set => ({
  auth: {
    ...initialState,

    initialize: opts => {
      set(
        state => {
          state.auth.auth = opts.auth;
          state.auth.group = opts.group;
          state.auth.groupName = opts.groupName;
          state.auth.groups = opts.groups;
          state.auth.hashedGroup = maskCredential(opts.group);
          state.auth.isGroupLoggedIn = opts.isGroupLoggedIn;
          state.auth.selectedAuth = opts.selectedAuth;
        },
        false,
        actionLog('initialize auth slice', opts),
      );
    },

    updateAuth: (newAuth, prioritizeNewAuth = true) => {
      set(
        state => {
          if (prioritizeNewAuth) {
            state.auth.auth = { ...state.auth.auth, ...newAuth };
          } else {
            state.auth.auth = { ...newAuth, ...state.auth.auth };
          }
        },
        false,
        actionLog('updateAuth', { newAuth, prioritizeNewAuth }),
      );
    },

    updateGroup: ({ apiDefinition, groupId: nextGroupId, user }) => {
      set(
        state => {
          if (nextGroupId === state.auth.group) {
            return;
          }

          const nextGroupName = getGroupNameById(user?.keys, nextGroupId);
          state.auth.group = nextGroupId;
          state.auth.hashedGroup = maskCredential(nextGroupId);
          state.auth.groupName = nextGroupName;

          if (nextGroupName) {
            const nextAuth = apiDefinition.getAuth(user || {}, nextGroupName) as ReferenceAuthSliceState['auth'];
            state.auth.auth = { ...state.auth.auth, ...nextAuth };
          }
        },
        false,
        actionLog('updateGroup', { apiDefinition, nextGroupId }),
      );
    },

    updateSelectedAuth: nextSelectedAuth => {
      set(
        state => {
          state.auth.selectedAuth = nextSelectedAuth;
        },
        false,
        actionLog('updateSelectedAuth', nextSelectedAuth),
      );
    },
  },
});
