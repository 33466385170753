import { create as createHash } from '@readme/ssri';

/* This will generate an integrity hash that looks something like this:
 *
 * sha512-Naxska/M1INY/thefLQ49sExJ8E+89Q2bz/nC4Pet52iSRPtI9w3Cyg0QdZExt0uUbbnfMJZ0qTabiLJxw6Wrg==?1345
 *
 * With the last 4 digits on the end for us to use to identify it later in a list.
 *
 * This function should match:
 * https://github.com/readmeio/metrics-sdks/blob/1458c2f43f5bd9ecea2f0419c2d14574bdfa25c5/packages/node/src/lib/construct-payload.ts#L114-L127=
 *
 * Taken from code written by @domharrington, with <3
 */
export const maskCredential = (credential?: number | string) => {
  if (!credential) return '';

  const castCredential = credential.toString();
  const transparentPortion = castCredential.slice(-4);

  return createHash(castCredential, {
    algorithm: 'sha512',
    options: [transparentPortion],
  }).toString();
};
