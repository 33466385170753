import type { ForwardedRef } from 'react';

import React, { useMemo, forwardRef, memo } from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './style.module.scss';

type Style = 'card' | 'flat' | 'pop' | 'rule';
type Props = React.HTMLAttributes<HTMLElement> & {
  Tag?: React.ElementType;
  forwardedRef?: React.ForwardedRef<HTMLElement>;
  kind?: Style;
  theme?: 'dark' | 'light';
};

function Box(
  { className, children, kind = 'flat', Tag = 'div', theme, ...rest }: Props,
  ref: ForwardedRef<HTMLElement>,
) {
  const bem = useClassy(classes, 'Box');
  const _className = useMemo(
    () => `${bem('&', `_${kind}`, theme === 'dark' && '_dark', className)} Box`,
    [bem, className, kind, theme],
  );

  return (
    <Tag ref={ref} className={_className} {...rest}>
      {children}
    </Tag>
  );
}

export default memo(forwardRef(Box));
