import type { ReferenceStore } from '..';
import type { DataForHAR } from '@readme/oas-to-har/lib/types';
import type { WritableDraft } from 'immer/dist/internal.js';
import type { StateCreator } from 'zustand';

import { actionLog } from '@core/store/util';

type WritableServer = WritableDraft<{ selected: number; variables?: Record<string, unknown> | undefined }>;

export interface ReferenceFormSliceState {
  /**
   * Data that pertains to our raw JSON editor
   */
  rawJsonEditor: {
    /** Indicates whether raw JSON editor is enabled or not */
    isEnabled: boolean;

    /**
     * The valid, parseable JSON that will be surfaced in the request body
     *
     * @example { test: 2 }
     */
    parsedJson: Record<string, unknown>;

    /**
     * The "raw" JSON user input.
     * This is a string so it can be valid or invalid.
     *
     * @example '{"test": 1}'
     */
    stringifiedJson: string;

    /**
     * Any JSON validation errors when attempting to take
     * `stringifiedJson` and parse it into `parsedJson`
     *
     * @example "Expected ':' after property name in JSON at position 7"
     */
    validationError?: string;
  };

  /**
   * Data that pertains to our schema form editor
   */
  schemaEditor: {
    /**
     * Object containing the schema form data as HAR
     * the way that `@readme/oas-to-har` expects it
     *
     * @example { body: { name: 'buster' }, path: { petId: 123 } }
     */
    data: DataForHAR;

    /**
     * Object containing explorer file uploads.
     *
     * @note In our explorer, any files sent as body parameters
     * are stored as a data URL within the `data.body` object
     * (defined above)
     *
     * @example { 'openapi.json': {} }
     */
    files: Record<string, unknown>;

    /** Indicates whether schema form is dirty or not */
    isDirty: boolean;
  };

  /**
   * Variables that track the named example the
   * user has selected. Used in pairing response examples
   * with request examples.
   */
  selectedRequestExampleKey: string | null;
}

export interface ReferenceFormSliceActions {
  /**
   * Returns the current form data, accounting for
   * if the body should be sourced from the raw JSON editor.
   */
  getCurrentFormData: () => ReferenceFormSliceState['schemaEditor']['data'];

  /**
   * Initializes the store. This generally runs every time a new endpoint is selected.
   */
  initialize: (opts?: {
    /**
     * Optional argument for populating default server variables
     * (e.g., `schemaEditor.data.server`).
     */
    server?: NonNullable<ReferenceFormSliceState['schemaEditor']['data']['server']>;
  }) => void;

  /**
   * Resets form store, with optional object with flags for partial resets.
   * If zero arguments (or empty object) are passed, `initialize` is run.
   */
  reset: (opts?: { jsonForm?: boolean; requestExample?: boolean; schemaForm?: boolean }) => void;

  /**
   * Enables/disables the raw JSON editor and also clears out
   * the currently selected request example and any dirty form data
   */
  toggleJsonEditor: () => void;

  /**
   * Records any updates made in the raw JSON editor
   */
  updateRawJsonFormData: (rawJsonData: string) => void;

  /**
   * Updates the currently selected request example, and takes its payload
   * and merges it with the schema form editor and/or raw JSON editor.
   * If `null` is passed as the `key`, it clears the current editor.
   */
  updateRequestExample: (
    /** The key (i.e., identifier) of the request example within the operation */
    key: ReferenceFormSliceState['selectedRequestExampleKey'],
    /** The request example payload */
    requestBody?: unknown,
    /** Indicates whether the request example payload is form-encoded or not */
    isFormEncoded?: boolean,
  ) => void;

  /**
   * Records any updates made in the schema form editor
   */
  updateSchemaFormData: (
    data: ReferenceFormSliceState['schemaEditor']['data'],
    files?: ReferenceFormSliceState['schemaEditor']['files'],
  ) => void;

  /**
   * Updates the server selection in our HAR store (e.g., `schemaEditor.data.server`)
   */
  updateServer: (
    newServer: ReferenceFormSliceState['schemaEditor']['data']['server'],
    prioritizeNewServer?: boolean,
  ) => void;
}

export interface ReferenceFormSlice {
  /**
   * State slice containing fields and actions that are relevant when reading/writing
   * form data in the Reference. This slice includes data about the schema form,
   * raw JSON editor, server variable input, and any other form input data required for
   * constructing the HAR (minus authentication, which is stored in the `auth` slice).
   */
  form: ReferenceFormSliceActions & ReferenceFormSliceState;
}

export const initialState: ReferenceFormSliceState = {
  rawJsonEditor: {
    isEnabled: false,
    parsedJson: {},
    stringifiedJson: '{}',
  },
  schemaEditor: {
    data: {
      server: { selected: 0, variables: {} },
    },
    files: {},
    isDirty: false,
  },
  selectedRequestExampleKey: null,
};

/**
 * Form state slice containing fields related to the schema form editor
 */
export const createReferenceFormSlice: StateCreator<
  ReferenceFormSlice & ReferenceStore,
  [['zustand/immer', never], ['zustand/devtools', never]],
  [],
  ReferenceFormSlice
> = (set, get) => ({
  form: {
    ...initialState,

    getCurrentFormData: () => {
      if (!get().form.rawJsonEditor.isEnabled) {
        return get().form.schemaEditor.data;
      }

      return {
        ...get().form.schemaEditor.data,
        body: get().form.rawJsonEditor.parsedJson,
      };
    },

    reset: (opts = {}) => {
      if (!Object.keys(opts).length) {
        get().form.initialize();
        return;
      }

      if (opts.jsonForm) {
        get().form.updateRawJsonFormData('{}');
      }

      if (opts.requestExample) {
        get().form.updateRequestExample(null);
      }

      set(
        state => {
          if (opts.schemaForm) {
            state.form.schemaEditor = initialState.schemaEditor;
          }
        },
        false,
        actionLog('reset form slice', opts),
      );
    },

    toggleJsonEditor: () => {
      const isCurrentlyToggled = get().form.rawJsonEditor.isEnabled;
      get().form.updateRequestExample(null);

      set(
        state => {
          state.form.rawJsonEditor.isEnabled = !isCurrentlyToggled;
        },
        false,
        actionLog('toggleJsonEditor'),
      );
    },

    updateRawJsonFormData: rawJsonData => {
      set(
        state => {
          let parsedJson;
          try {
            parsedJson = JSON.parse(rawJsonData);
            state.form.rawJsonEditor.stringifiedJson = rawJsonData;
            state.form.rawJsonEditor.parsedJson = parsedJson;
            state.form.rawJsonEditor.validationError = undefined;
          } catch (err) {
            state.form.rawJsonEditor.stringifiedJson = rawJsonData;
            state.form.rawJsonEditor.validationError = err.message;
          }
        },
        false,
        actionLog('updateRawJsonFormData', rawJsonData),
      );
    },

    updateRequestExample: (key, requestBody, isFormEncoded) => {
      const isJsonEditorEnabled = get().form.rawJsonEditor.isEnabled;

      if (!isJsonEditorEnabled) {
        get().form.updateSchemaFormData({
          ...get().form.schemaEditor.data,
          ...(isFormEncoded ? { formData: requestBody || {} } : { body: requestBody }),

          // logic to ensure that bodies are properly cleared out
          ...(requestBody === undefined && isFormEncoded === undefined ? { body: undefined, formData: undefined } : {}),
        });
      } else {
        get().form.updateRawJsonFormData(JSON.stringify(requestBody === undefined ? {} : requestBody, null, '\t'));
      }

      set(
        state => {
          state.form.selectedRequestExampleKey = key;
        },
        false,
        actionLog('updateRequestExample', { key, requestBody, isFormEncoded }),
      );
    },

    updateSchemaFormData: (data, files = {}) => {
      const previousData = get().form.schemaEditor.data;
      const previousFiles = get().form.schemaEditor.files;

      set(
        state => {
          state.form.schemaEditor = {
            data: { ...previousData, ...data },
            files: { ...previousFiles, ...files },
            isDirty: true,
          };
          state.selectedHar = null;
        },
        false,
        actionLog('updateSchemaFormData', { data, files }),
      );
    },

    updateServer: (newServer, prioritizeNewServer = true) => {
      set(
        state => {
          if (prioritizeNewServer) {
            state.form.schemaEditor.data.server = {
              ...state.form.schemaEditor.data.server,
              ...newServer,
            } as WritableServer;
          } else {
            state.form.schemaEditor.data.server = {
              ...newServer,
              ...state.form.schemaEditor.data.server,
            } as WritableServer;
          }
        },
        false,
        actionLog('updateServer', newServer),
      );
    },

    initialize: (opts = {}) => {
      set(
        state => {
          const server = opts?.server || initialState.schemaEditor.data.server;
          state.form = {
            ...state.form,
            ...initialState,
            schemaEditor: {
              data: {
                server,
              },
              files: {},
              isDirty: false,
            },
          };
          state.selectedHar = null;
        },
        false,
        actionLog('initialize form slice', opts),
      );
    },
  },
});
