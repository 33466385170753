import { useCallback, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import type { UserContextValue } from '@core/context';
import { BaseUrlContext, UserContext, LocalizationContext, ProjectContext } from '@core/context';
import useDebounced from '@core/hooks/useDebounced';
import { fetcher } from '@core/hooks/useReadmeApi';

const ROUTES_FOR_PAGE_LOAD_EVENT = ['recipes', 'edits', 'suggested-edits'];

const usePageViewMetric = () => {
  const { pathname } = useLocation();

  const { user } = useContext(UserContext) as unknown as UserContextValue;
  const { project } = useContext(ProjectContext);
  const language = useContext(LocalizationContext);
  const baseUrl = useContext(BaseUrlContext);

  /*
   * Debouncing is necessary for pageview metrics as we sometimes update the path in quick succession automatically.
   * For example when a user click on the reference section they go to `/reference` and then we will then update the
   * path to the match the first operation in the Oas; therefore, the path might become something like `/reference/_get`.
   */
  const requestCb = useCallback(() => {
    if (project?.childrenProjects?.length) return;

    const payload = {
      path: pathname,
      type: pathname.split('/')[1] || '',
      email: user?.email,
      language,
    };

    if (Object.values(payload).length) {
      // Manually triggering the pageLoad event for weird and/or Angular-powered routes
      if (ROUTES_FOR_PAGE_LOAD_EVENT.includes(payload.type)) {
        const titleMapping = {
          recipes: 'Recipes',
          edits: 'Suggest An Edit',
          'suggested-edits': 'Your Suggested Edits',
        };

        $(window).trigger('pageLoad', {
          opts: { reactApp: 'on' },
          hash: window.location.hash,
          name: payload.type,
          fallback: true,
          meta: {
            // We're monkey patching a title here so it doesn't break the Segment integration
            title: titleMapping[payload.type],
          },
        });
      }

      const host = baseUrl !== '/' ? baseUrl : '';
      fetcher(`${host}/pageview`, {
        method: 'post',
        body: JSON.stringify(payload),
      });
    }
  }, [project?.childrenProjects?.length, pathname, user?.email, language, baseUrl]);

  const debouncedRequest = useDebounced(requestCb, 200);

  useEffect(() => {
    debouncedRequest();
  }, [debouncedRequest]);

  return pathname;
};

export default usePageViewMetric;
