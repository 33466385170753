import type { SearchResults } from '@readme/api/src/mappings/search/types';

import pick from 'lodash/pick';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { IProjectMetadata } from '@Hub/Search/types';
import { TabNames } from '@Hub/Search/types';
import type { SearchTab } from '@Hub/SuperHubSearch/types';

import { createBoundedUseStore, actionLog } from '../util';

interface SearchStoreState {
  /** Active tab for search results */
  activeTab: TabNames;
  /** Tabs to display based on available project modules */
  displayedTabs: string[];
  /** Loading state for search results (from SWR fetcher) */
  isLoading: boolean;
  /** Query for search */
  prompt: string;
  /** Search results */
  results: SearchResults;
  /** Used to filter search results by type (section) for tabs */
  sectionFilter: string;
  /** Used to filter search results by subdomain (for Enterprise Groups) */
  subdomainFilters: string[];
}

interface SearchStoreAction {
  /**
   * Takes project metadata and available modules
   * and builds available tabs
   */
  buildDisplayedTabs: (projectsMeta: IProjectMetadata[]) => void;

  /**
   * Resets state back to the last "initialized" state
   */
  reset: () => void;

  /**
   * Sets the search results
   * @example
   * setResults({ data: [], total: 0 });
   */
  setResults: (results: SearchResults, isLoading: boolean) => void;

  /**
   * Set subdomain filters
   * @example
   * setSubdomainFilters(['subdomain']);
   */
  setSubdomainFilters: (subdomains: string[]) => void;

  /**
   * Updates the state's stored section filter and active tab
   * @example
   * setTab({ moduleName: 'guides', tabName: 'Guides' });
   */
  setTab: ({ searchModuleName, moduleName, label }: SearchTab) => void;

  /**
   * Updates the state's stored search/AI prompt
   * @example
   * updatePrompt('upload an API spec');
   */
  updatePrompt: (input: string) => void;
}

export type SearchStore = SearchStoreAction & SearchStoreState;

/**
 * Converts a list of project schema shells into
 * an object that contains a key for the project and value for all enabled/disabled modules
 * for that project.
 * @returns {object} { developers: { guides: true, reference: false } }
 */
const enableModuleMap = (metadata: IProjectMetadata[]) => {
  return (metadata || []).reduce(
    (obj, { subdomain, modules }) => {
      obj[subdomain] = modules;
      return obj;
    },
    {} as Record<string, Record<string, boolean>>,
  );
};

/**
 * Util that builds the array of tabs to display based on the enabled modules and subdomain filters
 * @returns {string[]} ['Guides', 'Reference']
 */
const buildDisplayTabs = (enabledModules: Record<string, Record<string, boolean>>, subdomainFilters: string[]) => {
  // We'll pick the selected modules based on any subdomain filters
  const selectedModules = subdomainFilters?.length ? pick(enabledModules, subdomainFilters) : enabledModules;

  // Loop over enabled modules and match subdomain filters to keys
  const values = Object.values(selectedModules);
  const modules = Object.values(values).reduce((obj, module) => {
    Object.keys(module).forEach(key => {
      if (obj[key]) obj[key] = !!(obj[key] || module[key]);
      else obj[key] = !!module[key];
    });

    return obj;
  }, {});

  return Object.entries(modules)
    .filter(([, v]) => v)
    .map(([k]) => k);
};

const initialState: SearchStoreState = {
  activeTab: TabNames.All,
  displayedTabs: [],
  prompt: '',
  sectionFilter: '',
  subdomainFilters: [],
  results: {
    data: [],
    total: 0,
  },
  isLoading: false,
};

/**
 * Add store description and example
 */
export const searchStore = createStore<SearchStore>()(
  immer(
    devtools(
      set => {
        const resetState = initialState;

        return {
          ...initialState,

          reset: () => {
            set(resetState, false, actionLog('reset'));
          },

          setTab(tab) {
            set(
              state => {
                state.sectionFilter = tab.searchModuleName || tab.moduleName;
                state.activeTab = tab.label;
              },
              false,
              actionLog('setTab', tab),
            );
          },

          buildDisplayedTabs(projectsMeta) {
            set(
              state => {
                const newEnabledModules = enableModuleMap(projectsMeta);
                const newDisplayedTabs = buildDisplayTabs(newEnabledModules, state.subdomainFilters);

                state.displayedTabs = newDisplayedTabs;
              },
              false,
              actionLog('buildDisplayedTabs', projectsMeta),
            );
          },

          setResults(results, isLoading) {
            set(
              state => {
                state.results = results;
                state.isLoading = isLoading;
              },
              false,
              actionLog('setResults', results),
            );
          },

          setSubdomainFilters(subdomains) {
            set(
              state => {
                state.subdomainFilters = subdomains;
              },
              false,
              actionLog('setSubdomainFilters', subdomains),
            );
          },

          updatePrompt(input) {
            set(
              state => {
                state.prompt = input;
              },
              false,
              actionLog('updatePrompt', input),
            );
          },
        };
      },
      { name: 'SearchStore' },
    ),
  ),
);

/**
 * Bound react hook to access our project store. Must be called within a React
 * component. To access the store outside of React, use `searchStore` instead.
 */
export const useSearchStore = createBoundedUseStore(searchStore);
