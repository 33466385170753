import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createBoundedUseStore, actionLog } from '../util';

// custom tone
export enum Tone {
  Neutral = 'neutral',
  Professional = 'professional',
  Whimsical = 'whimsical',
  // eslint-disable-next-line typescript-sort-keys/string-enum
  Custom = 'custom',
}

// length in words
export enum AnswerLength {
  Long = 'long',
  Medium = 'medium',
  Short = 'short',
}

export interface OwlbotStoreState {
  answerLength: AnswerLength;
  customTone: string | null;
  defaultAnswer: string;
  forbiddenWords: string;
  tone: Tone;
}

export interface OwlbotStoreActions {
  reset: (resetData?: OwlbotStoreState) => void;
  updateAnswerLength: (length: AnswerLength) => void;
  updateCustomTone: (tone: string) => void;
  updateDefaultAnswer: (answer: string) => void;
  updateForbiddenWords: (words: string) => void;
  updateTone: (tone: Tone) => void;
}

export type OwlbotStore = OwlbotStoreActions & OwlbotStoreState;

const initialState: OwlbotStoreState = {
  answerLength: AnswerLength.Short,
  customTone: null,
  defaultAnswer: '',
  forbiddenWords: '',
  tone: Tone.Neutral,
};

export const owlbotStore = createStore<OwlbotStore>()(
  immer(
    devtools(
      set => {
        let resetState = initialState;

        return {
          ...initialState,

          updateAnswerLength: length => {
            set(
              state => {
                state.answerLength = length;
              },
              false,
              actionLog('updateAnswerLength', length),
            );
          },

          updateDefaultAnswer: answer => {
            set(
              state => {
                state.defaultAnswer = answer;
              },
              false,
              actionLog('updateDefaultAnswer', answer),
            );
          },

          updateCustomTone: customTone => {
            set(
              state => {
                state.customTone = customTone;
              },
              false,
              actionLog('updateCustomTone', customTone),
            );
          },

          updateForbiddenWords: words => {
            set(
              state => {
                state.forbiddenWords = words;
              },
              false,
              actionLog('updateForbiddenWords', words),
            );
          },

          updateTone: tone => {
            set(
              state => {
                state.tone = tone;
              },
              false,
              actionLog('updateTone', tone),
            );
          },

          reset: (resetData?: OwlbotStoreState) => {
            resetState = resetData ?? initialState;
            set(
              state => {
                state.answerLength = resetState.answerLength;
                state.defaultAnswer = resetState.defaultAnswer;
                state.forbiddenWords = resetState.forbiddenWords;
                state.tone = resetState.tone;
                state.customTone = resetState.customTone;
              },
              false,
              actionLog('reset'),
            );
          },
        };
      },
      { name: 'OwlbotStore' },
    ),
  ),
);

export const useOwlbotStore = createBoundedUseStore(owlbotStore);
