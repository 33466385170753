import type { SuperHubStore } from '..';
import type { GitSidebarCategory, GitSidebarQueryString } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useEffect } from 'react';

import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSuperHubStore } from '..';

/**
 * Empty list to use as the default fallback when SWR hook is in transit.
 */
const emptyState = [];

/**
 * Hash of route sections to its respective sidebar page type.
 */
export const superHubSidebarTypes: Record<
  NonNullable<Exclude<SuperHubStore['routeSection'], 'recipes'>>,
  GitSidebarQueryString['page_type']
> = {
  'api-definition': 'reference',
  changelog: 'changelog',
  docs: 'guide',
  page: 'custom_page',
  reference: 'reference',
};

/**
 * Connects our `SuperHubStore` sidebar slice to the git sidebar API endpoint to
 * continually hydrate its data and keeps it in sync.
 */
export function ConnectSuperHubSidebarToApi({ children }: { children: React.ReactNode }) {
  const [apiBaseUrl, initialize, routeSection] = useSuperHubStore(s => [
    s.apiBaseUrl,
    s.sidebar.initialize,
    s.routeSection,
  ]);

  const apiUrl = routeSection ? `${apiBaseUrl}/sidebar?page_type=${superHubSidebarTypes[routeSection]}` : null;
  const {
    data = emptyState,
    error = null,
    isLoading,
    swrKey,
  } = useReadmeApi<GitSidebarCategory[]>(apiUrl, {
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });

  useEffect(() => {
    initialize({
      data,
      error,
      isLoading,
      swrKey,
    });
  }, [data, error, initialize, isLoading, swrKey]);

  return <>{children}</>;
}
